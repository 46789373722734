import React, { useState, useEffect } from 'react'
import DeleteBin from '../../assests/icons/bin.png'
import { addProviderOutReach, assignProvider, deleteProviderOutReach, editProviderOutReach } from '../../../utils/ApiClient';
import CustomSelectWithButtons from '../../common/DropdownMenu/DropdownWithConfirmationBar';
import { ProviderStatus, providerAssignDialog, deleteProviderDialog, assigningProviderDialog, storageKeys, userType, PRTUserCasesType, SideBarTabItemType1, EAPXCaseStatusEnum, CaseTypeEnum } from '../../../utils/constants';
import ConfirmationDialogBox from '../../common/DialogBox/ConfirmDialogBox'
import useGlobalState from '../../../context/useGlobalState';
import { validateRequiredFields, extractDateTime } from '../../../utils/helperFunctions';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingIcon from '../../assests/icons/loader.png';
import { ProvidersType, Provider } from './PRTDashboardHome';
import './Provider.scss'
import { ApiEAPXCase, ApiPRTCaseDetails } from '../../../interfaces/ApiTypes';
import InlineError from '../../common/Error/InlineError';

interface AdditionalProviderProps {
  provider: Provider;
  providerNo: number;
  activeCaseUUID: string;
  setProviders: React.Dispatch<React.SetStateAction<ProvidersType>>;
  providers: ProvidersType;
  caseStatus: string;
  setCasesTypeName: (caseTpe: string) => void;
  setNoData: (data: boolean) => void;
  setLoading: (loading: boolean) => void;
  setCases: (cases: ApiEAPXCase[]) => void;
  casesTypeName: string;
  onCaseTypeTabChange?: (caseType: string) => void;
}

const ProviderComponent: React.FC<AdditionalProviderProps> = ({ provider, providerNo, activeCaseUUID, setProviders, caseStatus, setCasesTypeName, casesTypeName, setLoading, onCaseTypeTabChange }) => {
  const [providerInfo, setProviderInfo] = useState<Provider>({
    eapx_case: "",
    eapx_file_number: undefined,
    id: 0,
    provider_detail: "",
    status: "",
    full_address: "",
    phone: "",
    email: "",
    notes: "",
    assigned_date: null,
    provider_status: ProviderStatus.REQUEST_SENT.value,
    provider_status_display: ProviderStatus.REQUEST_SENT.label,
    file_number: 0
  })
  const [errors, setErrors] = useState<string>("");
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openAssignProvider, setOpenAssignProvider] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [status, setStatus] = useState<string | null | undefined>(null)
  const [isDialogButtonDisabled, setIsDialogButtonDisabled] = useState<boolean>(false)
  const [closingCase, setClosingCase] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)
  const { globalErrorHandler } = useGlobalState();
  const disabled = (casesTypeName === PRTUserCasesType.CLOSED.label)
  const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)
  const isManager = userTypeName === userType.PRT_MANAGER
  const disabledText = casesTypeName === PRTUserCasesType.CLOSED.label && caseStatus === EAPXCaseStatusEnum.ACCEPTED && provider?.assigned_date

  useEffect(() => {
    setProviderInfo(provider)
  }, [provider])

  useEffect(() => {
    setStatus((provider?.provider_status_display ?? null) as string | null)
  }, [provider?.provider_status_display])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setProviderInfo((prev: Provider) => ({
      ...prev,
      [name]: value,
    }));
  }

  const removeProvider = (index: number) => {
    setProviders((prevProviders: ProvidersType) => {
      let updatedProviders = { ...prevProviders };
      updatedProviders = [
        ...prevProviders.slice(0, index),
        ...prevProviders.slice(index + 1)
      ];
      return updatedProviders;
    });
  };

  const saveProviderDetails = async (providerIndex: number): Promise<void> => {
    const result = validateRequiredFields(providerInfo)
    if (!result.valid) {
      setErrors(result.message);
    } else {
      setErrors("")
      const matchedValue = Object.values(ProviderStatus).find(item => item.label === status);
      const data = {
        eapx_file_number: providerInfo.eapx_file_number,
        eapx_case: activeCaseUUID,
        provider_detail: providerInfo.provider_detail,
        full_address: providerInfo.full_address,
        email: providerInfo.email,
        phone: providerInfo.phone,
        notes: providerInfo.notes,
        provider_status: matchedValue?.value
      };
      try {
        const response = await addProviderOutReach(data);
        setErrors("")
        setLoader(true)
        setTimeout(() => {
          setLoader(false)
          const newProvider = { ...providerInfo, status: "Saved" };
          setProviderInfo(newProvider);
          setProviders((prev: ProvidersType) =>
            prev.map((provider: Provider, index: number) =>
              index === providerIndex ? newProvider : provider
            )
          );
        }, 1000)
        setTimeout(() => {
          const newProvider = { ...providerInfo, id: response.data.data.id, provider_status: matchedValue, provider_status_display: status, status: "Save" };
          setProviderInfo(newProvider);
          setProviders((prev: ProvidersType) =>
            prev.map((provider: Provider, index: number) =>
              index === providerIndex ? newProvider : provider
            )
          )
        }, 2000)
      } catch (error) {
        globalErrorHandler(error);
      }
    }
  };

  const changeButtonName = (data: Provider) => {
    setLoader(false)
    setTimeout(() => {
      const updatedData = { ...providerInfo, ...data, status: "Save" };
      setProviderInfo(updatedData);
      setProviders((prev: ProvidersType) =>
        prev.map((provider: Provider, index: number) =>
          provider.id === providerInfo.id ? updatedData : provider
        )
      )
    }, 1000)
  }

  const updateProvider = async (): Promise<void> => {
    const matchedValue = Object.values(ProviderStatus).find((item: SideBarTabItemType1) => item.label === status);
    const data = { ...providerInfo, provider_status: matchedValue?.value, provider_status_display: status };
    delete data.status;
    try {
      await editProviderOutReach(providerInfo.id, data);
      setLoader(true)
      setTimeout(() => {
        const updatedData = { ...providerInfo, ...data, status: "Saved" };
        setProviderInfo(updatedData);
        setProviders((prev: ProvidersType) =>
          prev.map((provider: Provider, index: number) =>
            provider.id === providerInfo.id ? updatedData : provider
          )
        );
      }, 1000)
      setTimeout(() => {
        changeButtonName(data)
      }, 1000)
    } catch (error) {
      globalErrorHandler(error);
      setProviderInfo({ ...provider });
    }
  };

  const removeProviderOutreach = async (providerId: number): Promise<void> => {
    setIsDialogButtonDisabled(true)
    try {
      await deleteProviderOutReach(providerId);
      setProviders((prev: ProvidersType) =>
        prev.filter((provider: Provider) => provider.id !== providerId)
      );
      setIsDialogButtonDisabled(false)
      handleCancelButtonPressed()
    } catch (error) {
      setIsDialogButtonDisabled(false)
      handleCancelButtonPressed()
      globalErrorHandler(error);
    }
  };

  const handlePopUp = (index: number) => {
    if (providerInfo.id !== 0) {
      setOpenDeleteDialog(true)
    } else {
      removeProvider(index)
    }
  }

  const handleCancelButtonPressed = () => {
    if (openDeleteDialog) {
      setOpenDeleteDialog(false)
    }
    if (openDialogBox) {
      setOpenDialogBox(false)
    }
    if (openAssignProvider) {
      setOpenAssignProvider(false)
    }
  }

  const assignProviderOutreach = async (id: number): Promise<void> => {
    setIsDialogButtonDisabled(true)
    handleCancelButtonPressed()
    setClosingCase(true)
    try {
      const response = await assignProvider(id)
      const dateAndTime = extractDateTime(response.data.data.assigned_date);
      let _message = "PROVIDER ASSIGNED - " + dateAndTime
      setSuccessMessage(_message)
      setIsDialogButtonDisabled(false)
      setClosingCase(false)
      setLoading(true)
      if (onCaseTypeTabChange) onCaseTypeTabChange(CaseTypeEnum.CLOSED)
      setCasesTypeName(PRTUserCasesType.CLOSED.label);
      setLoading(false)
    } catch (error) {
      setIsDialogButtonDisabled(false)
      handleCancelButtonPressed()
      setClosingCase(false)
      globalErrorHandler(error)
    }
  }

  const handleAssign = async (): Promise<void> => {
    const status = ProviderStatus.PROVIDER_ACCEPTED.value
    if (ProviderStatus.PROVIDER_ACCEPTED.value === providerInfo.provider_status) {
      setOpenAssignProvider(true) 
    }
    else if (providerInfo.provider_status !== status) {
      setOpenDialogBox(true)
    }
  }

  return (
    <div className='additional-provider-component'>
      <div className='provider-action-header'>
        <span className='provider-title'>Provider #{providerNo + 1}</span>
        {
          successMessage ? <span className='success message-block'>{successMessage}</span> : <div>
            {
              caseStatus === EAPXCaseStatusEnum.RE_OPENED && provider?.assigned_date ?
                <span className='success message-block'> PROVIDER ASSIGNED - {extractDateTime(provider?.assigned_date)}</span> :
                <div className='buttons-section'>
                  {
                    casesTypeName === PRTUserCasesType.CLOSED.label && caseStatus === EAPXCaseStatusEnum.ACCEPTED && provider?.assigned_date ?
                      <span className={disabledText ? 'disbled-block success message-block' : 'success message-block'}> PROVIDER ASSIGNED - {extractDateTime(provider?.assigned_date)}</span> :
                      <div className='dropdown-section'>
                        <CustomSelectWithButtons
                          options={ProviderStatus}
                          name='provider-status'
                          stateChange={setStatus}
                          state={status}
                          showButtons={false}
                          fileNo={providerInfo.file_number}
                          showPopUp={true}
                          casesTypeName={casesTypeName} />
                      </div>
                  }
                  {
                    !(casesTypeName === PRTUserCasesType.CLOSED.label || isManager) && <div className="buttons">
                      <img src={DeleteBin} alt='delete' className='delete-icon' onClick={() => handlePopUp(providerNo)} />
                      <button
                        className='save-button'
                        onClick={() => providerInfo.id > 0 ? updateProvider() : saveProviderDetails(providerNo)}>
                        {loader ? <img src={LoadingIcon} alt='loading' /> : <>{provider.status === "Saved" ? "SAVED!" : "SAVE"}</>}
                      </button>
                      <button
                        className='assign-button'
                        onClick={handleAssign}>
                        {"Assign"}
                      </button>
                    </div>
                  }
                </div>
            }
          </div>
        }
      </div>
      <div className='fields'>
        <input type='text' className={disabled || isManager || provider?.assigned_date ? 'input-box-disabled' : 'input-box'} placeholder='First name, last name, license type or group name*' name='provider_detail' value={providerInfo.provider_detail} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
        <input type='text' className={disabled || isManager || provider?.assigned_date ? 'input-box-disabled' : 'input-box'} placeholder='Tela health or full address*' name='full_address' value={providerInfo.full_address} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
        <input type='text' className={disabled || isManager || provider?.assigned_date ? 'input-box-disabled' : 'input-box'} placeholder='Phone*' name='phone' value={providerInfo.phone} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
        <input type='text' className={disabled || isManager || provider?.assigned_date ? 'input-box-disabled' : 'input-box'} placeholder='Email' name='email' value={providerInfo.email} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
        <input type='text' className={disabled || isManager || provider?.assigned_date ? 'input-box-disabled' : 'input-box'} placeholder='Note' name='notes' value={providerInfo.notes} onChange={handleChange} disabled={disabled || isManager || (provider?.assigned_date ? true : false)} />
      </div>
      <div>{errors && <InlineError errorMessage={errors} />}
      </div>
      <ConfirmationDialogBox
        title={providerAssignDialog.title}
        content={providerAssignDialog.content}
        onConfirm={() => assignProviderOutreach(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={providerAssignDialog.confirmLabel}
        openDialogBox={openDialogBox}
        buttonDisabled={isDialogButtonDisabled} />
      <ConfirmationDialogBox
        title={deleteProviderDialog.title}
        content={deleteProviderDialog.content}
        onConfirm={() => removeProviderOutreach(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={deleteProviderDialog.confirmLabel}
        openDialogBox={openDeleteDialog}
        buttonDisabled={isDialogButtonDisabled} />
      <ConfirmationDialogBox
        title={assigningProviderDialog.title}
        content={assigningProviderDialog.content}
        onConfirm={() => assignProviderOutreach(providerInfo.id)}
        handleClose={handleCancelButtonPressed}
        confirmLabel={assigningProviderDialog.confirmLabel}
        openDialogBox={openAssignProvider}
        buttonDisabled={isDialogButtonDisabled} />
      <Backdrop open={closingCase} style={{ color: 'white',zIndex:1301 }}>
        <div className='backdrop-loader'>
          <CircularProgress style={{ color: 'rgba(0, 0, 0, 0.12) ' }} />
          <span>{"CLOSING CASE"}</span>
        </div>
      </Backdrop>
    </div>
  )
}

export default React.memo(ProviderComponent)
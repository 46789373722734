import { useEffect, useRef, useState } from 'react'
import EAPCaseSideBar from '../../common/SideTabs/eap-cases-sidebar/EAPCaseSideBar'
import Loader from '../../common/Loader/Loader'
import { PRTManagerCaseType  } from '../../../utils/constants'
import { EAPXCaseList } from '../../../utils/ApiClient'
import { ProvidersType } from '../prt-dashboard/PRTDashboardHome'
import CaseDetails from '../prt-dashboard/CaseDetails'
import Providers from '../prt-dashboard/Providers'
import '../prt-dashboard/PRTDashboardHome.scss'
import NoteSection from '../prt-dashboard/NoteSection'
import { ApiEAPXCase, ApiPRTCaseDetails } from '../../../interfaces/ApiTypes'
import useGlobalState from '../../../context/useGlobalState'

const PRTManagerDashboard = () => {
    const [activeCaseUUID, setActiveCaseUUID] = useState<any>("")
    const [cases, setCases] = useState<ApiEAPXCase[]>([])
    const [caseStatus, setCaseStatus] = useState<string>("")
    const [activeCaseInfo, setActiveCaseInfo] = useState<ApiPRTCaseDetails>()
    const [casesTypeName, setCasesTypeName] = useState<string>(PRTManagerCaseType.ALL_CASES.label)
    const [caseLoading, setCaseLoading] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [providers, setProviders] = useState<ProvidersType>([]);
    const [noData, setNoData] = useState(false)
    const [specialist, setSpecialist] = useState("")
    const [isUnassign, setIsUnassign] = useState<boolean>(false)
    const { globalErrorHandler } = useGlobalState()
    const [notes, setNotes] = useState<string>("")
    const effectRef = useRef(false)

    const caseData = (data: any) => {
        if (data !== null || data !== undefined) {
            setSpecialist(data?.assigned_specialist)
            setCaseStatus(data?.eapx_file_status_display)
            setNotes(data?.notes)
        }
    }

    const getEapxCases = async (caseType : string): Promise<void> => {
        try {
            const response = await EAPXCaseList(caseType)
            if (!response.data.data.cases.length) {
                setNoData(true)
                setCases([])
            }
            else {
                setCases(response.data.data.cases);
                setNoData(false)
            }
            setLoading(false)
            setCaseLoading(false)
            setIsUnassign(false)
        } catch (error) {
            globalErrorHandler(error)
            setLoading(false)
            setCaseLoading(false)
        }
    }

    useEffect(() => {
        if (effectRef.current === false) {
            getEapxCases( PRTManagerCaseType.ALL_CASES.value)
        }
        return () => { effectRef.current = true }
    }, [])

    useEffect(() => {
        if (activeCaseInfo) {
            setProviders(
                activeCaseInfo.provider_outreach.map((value: any) => ({ ...value, status: "Save" }))
            );
        }
    }, [activeCaseInfo]);
    
    useEffect(() => {
        if (cases?.length) {
            caseData(activeCaseInfo)
        }
    }, [activeCaseUUID, activeCaseInfo, cases, casesTypeName])

    useEffect(() => {
        if (isUnassign) {
            setLoading(true)
            getEapxCases( PRTManagerCaseType.ALL_CASES.value)
        }
    }, [isUnassign])

    return (
        <div className='eap-cases-page'>
            {
                loading ? <div className='loader-component-section'> <Loader /></div> :
                    <div className='case-info-section'>
                        <EAPCaseSideBar
                            onCaseTypeTabChange={getEapxCases}
                            tabs={PRTManagerCaseType}
                            cases={cases}
                            setLoading={setLoading}
                            setActiveCaseInfo={setActiveCaseInfo}
                            casesTypeName={casesTypeName}
                            setCasesTypeName={setCasesTypeName}
                            activeCaseUUID={activeCaseUUID}
                            setActiveCaseUUID={setActiveCaseUUID}
                            setCaseLoading={setCaseLoading}
                            caseStatus={caseStatus}
                            caseLoading={caseLoading}
                            setNoData={setNoData} />
                        {
                            caseLoading ? <div className='case-content'>
                                <Loader />
                            </div> : <div className='case-details-section'>
                                {
                                    noData ? <span className='no-data-section'>{"No cases found"}</span> :activeCaseUUID === "" ?<span className='no-data-section'>Please select a case from the left column</span>:
                                        <div className='case-content'>
                                            <CaseDetails
                                                activeCaseInfo={activeCaseInfo}
                                                caseStatus={caseStatus}
                                                caseLoading={caseLoading}
                                                setCaseLoading={setCaseLoading}
                                                setCaseStatus={setCaseStatus}
                                                activeCaseUUID={activeCaseUUID}
                                                providers={providers}
                                                setSpecialist={setSpecialist}
                                                specialist={specialist}
                                                casesTypeName={casesTypeName}
                                                setCasesTypeName={setCasesTypeName}
                                                setIsUnassign={setIsUnassign}
                                                loader={false} />
                                            <div className='case-sub-section'>
                                            {providers?.length === 0 ?<span className='no-additional-provider-section'>No provider outreach activity</span> : 
                                            <>
                                            {providers?.map((provider: any, index) => (
                                                    <Providers
                                                        key={index + 2}
                                                        providerNo={index}
                                                        provider={provider}
                                                        activeCaseUUID={activeCaseUUID}
                                                        setProviders={setProviders}
                                                        providers={providers}
                                                        caseStatus={caseStatus}
                                                        setCasesTypeName={setCasesTypeName}
                                                        setNoData={setNoData}
                                                        setLoading={setLoading}
                                                        setCases={setCases}
                                                        casesTypeName={casesTypeName} />
                                            ))}
                                            </>}
                                            </div>
                                            <NoteSection
                                                notes={notes}
                                                setNotes={setNotes}
                                                fileNo={activeCaseInfo?.file_number} />
                                        </div>
                                }
                            </div>
                        }
                    </div>
            }
        </div>
    )
}

export default PRTManagerDashboard
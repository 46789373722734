import { useEffect, useRef, useState } from "react"
import { deleteCoordinator, editCoordinator, getCoordinatorList } from "../../../utils/ApiClient";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import SearchBox from "../../common/SearchBox/SearchBox"
import Loader from "../../common/Loader/Loader"
import { PRT_Coordinators } from "../../../utils/constants";
import DataList from "../../common/PRT-Settings/DataList";
import CopyIcon from '../../assests/icons/line-copy.png';
import useGlobalState from "../../../context/useGlobalState";
import InlineError from "../../common/Error/InlineError";

const AllUsers = () => {
  const [coordinator, setCoordinator] = useState([])
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [buttonName, setButtonName] = useState<string>("Copy Link")
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [successMessage, setSuccessMessage] = useState<string>("")
  const [searchTermAvailable, setSearchTermAvailable] = useState<boolean>(false)
  const [noData, setNodata] = useState<string>("")
  const { globalErrorHandler } = useGlobalState()
  const effectRef = useRef(false)
  const spanRef = useRef<HTMLSpanElement>(null);

  const fetchCoOrdinator = async (term: string) => {
    try {
      const response = await getCoordinatorList(term ?? "")
      if (response.data.data.prt_coordinators.length === 0) {
        setNodata(term ? `Co-Ordinator “${term}” Not Found` : "No Co-Ordinators found")
        setLoading(false)
      } else
        setNodata("")
      setCoordinator(response.data.data.prt_coordinators)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      globalErrorHandler(error)
    }
  }

  const copyToClipboard = () => {
    const text = spanRef.current?.innerText;
    if (text) {
      navigator.clipboard.writeText(text)
        .then(() => {
          setButtonName('Copied!');
          setTimeout(() => {
            setButtonName('Copy Link');
          }, 5000);
        }).catch((error) => {
          globalErrorHandler(error)
        })
    }
  };

  const handleSearchClick = async (): Promise<void> => {
    if (searchTermAvailable) {
      setSearchTerm("")
      await fetchCoOrdinator("");
      setSearchTermAvailable(false);
      return;
    } else {
      if (!searchTerm) {
        setError(true)
        return
      }
      await fetchCoOrdinator(searchTerm);
      setSearchTermAvailable(true)
    }
  }

  useEffect(() => {
    if (error || successMessage) {
      setTimeout(() => {
        setError(false)
        setSuccessMessage("")
      }, 5000)
    }
  }, [error, successMessage])

  useEffect(() => {
    if (effectRef.current === false) {
      fetchCoOrdinator("")
    }
    return () => { effectRef.current = true }
  }, [])

  return (
    <div className="coordinator-component">
      {
        loading ? (
        <div className="loader-box">
          <Loader />
        </div>
      ) : (
        <div className="setting-component">
          <div className="setting-grid">
            <div className="setting-info">
              <span className="setting-title">{PRT_Coordinators.title}</span>
              <span className="setting-desciption">{PRT_Coordinators.description}</span>
            </div>
            <div className="searc-box-section">
              <SearchBox value={searchTerm} setValue={setSearchTerm} onClick={handleSearchClick} searchTermAvailable={searchTermAvailable} />
            </div>
          </div>
          <div className="link-section">
            <div className="link-section-underline">
              <a href={`${EnvironmentConfig.ccaBaseURL}/onboarding`} target="_blank" rel="noopener noreferrer" className="clickable-link">
                <span className="setting-desciption">Onboarding link: </span>
                <span className="setting-desciption" ref={spanRef}>
                  {EnvironmentConfig.ccaBaseURL}/onboarding
                </span>
              </a>
            </div>
            <button className="add-button" onClick={copyToClipboard}><img src={CopyIcon} alt="copy" className="copy-icon" />{buttonName}</button>
          </div>
          <div className="add-section">
            <span className="input-area">{"Full Name"}</span>
            <span className="input-area title-margin-email">{"Email"}</span>
          </div>
          {error && <InlineError errorMessage="Please enter Coordinator" />}
          {successMessage && <span className="success">{successMessage}</span>}
          {noData ? (
            <span className="no-data">{noData}</span>
          ) : (
            <DataList
              list={coordinator}
              hideButtons={false}
              deleteAPI={deleteCoordinator}
              fetchAPI={fetchCoOrdinator}
              editAPI={editCoordinator}
              setSuccessMessage={setSuccessMessage}
              setLoading={setLoading}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default AllUsers
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../assests/icons/logo.png";
import Toast from "../common/Toast/Toast";
import { storageKeys, common, userType } from "../../utils/constants";
import { logout } from "../../utils/ApiClient";
import useGlobalState from "../../context/useGlobalState";
import { clearLocalStorageExceptOID } from "../../utils/clearStorageData";
import "./FixedHeaderUserLayout.css";

interface Props {
  children: React.ReactNode;
}

const FixedHeaderUserLayout: React.FC<Props> = ({
  children,
}) => {
  const { globalErrorHandler } = useGlobalState();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const token = localStorage.getItem(storageKeys.TOKEN);
  const userTypeName = localStorage.getItem(storageKeys.USER_TYPE)

  const handleHomeNavigation = (): void => {
    if (token) {
      navigate('/dashboard')
    } else {
      navigate('/login')
    }
  };

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      clearLocalStorageExceptOID(storageKeys.ORGANIZATION_ID);
      navigate("/login");
    } catch (error) {
      globalErrorHandler(error);
    }
  };

  const goToSettings = () => {
    navigate('/prt-settings')
  }

  const goToPRTManagerDashboard = () => {
    navigate('/dashboard')
  }

  return (
    <div className="fixed-header-component fixed-header-layout">
      <header className="common-header smaller-fixed-header">
        <div className="header">
          <div className="header-content-left">
            <img
              src={logo}
              alt="logo"
              className="fixed-header-logo"
              onClick={handleHomeNavigation}
            />
          </div>
        </div>
        <>
          {
            !token ?
              <div className="onboarding-buttons">
                <span className={currentPath === "/onboarding" ? "active-page" : ""} onClick={() => navigate('/onboarding')}>{"Sign up"}</span>
                <span className={currentPath === "/login" || currentPath === '/' ? "active-page" : ""} onClick={() => navigate('/login')}>{"SIGN IN"}</span>
              </div> :
              <div>
                {
                  userTypeName === userType.PRT_MANAGER ? (
                    <div className="prt-buttons">
                      {
                        currentPath === "/dashboard" ? <span className="prt-text" onClick={goToSettings}>{common.PRT_SETTINGS}</span> : <span className="prt-text" onClick={goToPRTManagerDashboard}>{common.PRT_DASHBOARD}</span>
                      }
                      <button className="prt-logout-btn" onClick={handleLogout}>{"Log Out"}</button>
                    </div>
                  ) :
                    <div className="button-box">
                      <button className="prt-logout-btn" onClick={handleLogout}>
                        {"Log Out"}
                      </button>
                    </div>
                }
              </div>
          }
        </>
      </header>
      <Toast />
      <div className="content">{children}</div>
    </div>
  );
};

export default FixedHeaderUserLayout;
